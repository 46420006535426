import React, { useRef } from "react";
import moment from "moment";
import { PDFExport } from "@react-pdf/renderer";
import { usePDF } from "react-to-pdf";
import header_img from "../constant/images/bg-bannerid.jpg";
import footer_img from "../constant/images/footer_id.jpg";

const SQInvoice = ({ productData, gstadd, Site_Address }) => {
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  console.log(productData);

  // Generate table rows dynamically
  const tableRows = productData.items
    .map((item, index) => {
      // Ensure quantity and rate are numbers and default to 0 if undefined
      const quantity = Number(item.quantity || 0);
      const rate = Number(item.rate || 0);

      // Format rate and amount with Indian number format
      const formattedRate = rate;
      const amount = quantity * rate;

      return `
      <tr>
        <td>${index + 1}</td>
        <td>${item.commodity || "N/A"}</td>
        <td>${item.uom || "N/A"}</td>
        <td>${quantity}</td>
        <td>${formattedRate}</td>
        <td>${amount}</td>
      </tr>
    `;
    })
    .join("");

  // Calculate total quantity
  const totalQuantity = productData.items.reduce(
    (acc, item) => acc + item.quantity,
    0
  );
  const totalAmount = productData.items.reduce((total, { quantity, rate }) => {
    const numericQty = Number(quantity) * Number(rate);

    if (!isNaN(numericQty)) {
      return total + numericQty;
    }

    return total;
  }, 0);
  const templateHTML = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Downloadable Invoice</title>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Bookman+Old+Style:wght@400;700&display=swap');

        body {
          font-family: 'Bookman Old Style', serif;
          background-color: #f5f5f5;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
        }

        .invoice {
          font-family: 'Bookman Old Style', serif;
          width: 250mm;
          min-height: 347mm;
          margin: 0 auto;
          background-color: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          padding: 35px 50px;
          position: relative;
          display: flex;
          flex-direction: column;
        }

        .invoice-header {
          font-family: 'Bookman Old Style', serif;
          text-align: center;
        }

        .invoice-header img {
          max-width: 100%;
          height: auto;
        }

        .invoice-header h1 {
          font-size: 28px;
          margin: 10px;
          font-family: 'Bookman Old Style', serif;
          font-weight: 700;
        }

        .frome-to {
          font-size: 18px;
          font-family: 'Bookman Old Style', serif;
          font-weight: 400;
        }

        .invoice-details {
          font-family: 'Bookman Old Style', serif;
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }

        .text-adjust p {
        font-family: 'Bookman Old Style', serif;
         line-height : 10px;
         
        }
            .text-adjust p strong {
        font-family: 'Bookman Old Style', serif;
         line-height : 10px;
         font-weight: bold;    
         
        }
         .mt-80 {
         margin-top : 50px
         }

        .invoice-items {
          font-family: 'Bookman Old Style', serif;
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;
        }

        .invoice-items th,
        .invoice-items td {
          font-family: 'Bookman Old Style', serif;
          border: 1px solid #000;
          padding: 5px;
          text-align: left;
          height: 20px;
        }

        .invoice-items th {
          font-family: 'Bookman Old Style', serif;
          font-weight: 500;
        }

        .invoice-items td {
          font-family: 'Bookman Old Style', serif;
          font-weight: 800;
        }

        .terms-and-conditions {
          font-family: 'Bookman Old Style', serif;
          font-size: 14px;
           margin-top: 20px;
          
        }
        .terms-and-conditions strong {
          font-family: 'Bookman Old Style', serif;
          font-size: 18px;
          font-weight: 800;
        }
        .terms-and-conditions p {
          font-family: 'Bookman Old Style', serif;
          font-size: 18px;
          font-weight: 800;
          line-height : 18px;

        }

         .terms-and-conditions ol li {
          font-family: 'Bookman Old Style', serif;
          font-size: 14px;
          
        }

        .footer {
          font-family: 'Bookman Old Style', serif;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          padding: 10px 0;
        }

        .footer img {
          max-width: 100%;
          height: auto;
        }
        .line-space {
         line-height : 40px;
         
         }
      </style>
    </head>
    <body>
      <div class="invoice">
        <div class="invoice-header">
          <img src=${header_img} alt="Logo" />
          <h1>Scaffolding work Quotation</h1>
        </div>
        <div class="invoice-details">
          <div class="text-adjust mt-80">
           
            <p><strong>To:</strong> ${productData.name}</p>
            <p>${productData.address}</p>
           
          </div>
          <div class="text-adjust">
        <p>Date: ${moment(productData.date).format("DD-MM-YYYY")}</p>

          </div>
        </div>
                        <p><strong>Subject: Quotation for the supply of scaffolding Work With material for your site on rental
basis </strong></p>
                <p><strong> Dear Sir / Madam,</strong></p>
                <div>
                    <p> &emsp; &emsp; This letter has the reference to the discussion the undersigned had with you for Your enquiry for the Scaffolding and shuttering materials, for your esteemed project.We are the manufacturers Hirers and suppliers of the quality materials, for which <strong>We would like to propose the following lowest possible rate for the supply of the same </strong> </p>
                </div>
        <table class="invoice-items">
          <thead>
            <tr>
              <th>SL.NO</th>
              <th>Particulars</th>
              <th>UOM</th>
              <th>Quantity/PC</th>
              <th>Rate/Month</th>
              <th>Amount</th>


            </tr>
          </thead>
          <tbody>
            ${tableRows}
            <tr>
              <td style="border-color: transparent;"></td>
                        <td style="border-color: transparent;"></td>
                        <td style="border-color: transparent;"></td>
                        <td style="border-color: transparent;"></td>

              <td class="border-left-side" style="border-left: 2px solid #000;">Sub.Total</td>
              <td>${totalAmount}</td>
            </tr>
             <tr>
              <td style="border-color: transparent;"></td>
                        <td style="border-color: transparent;"></td>
                        <td style="border-color: transparent;"></td>
                        <td style="border-color: transparent;"></td>

              <td class="border-left-side" style="border-left: 2px solid #000;">Gst 18% </td>
              <td>${parseFloat(totalAmount * 0.18)}</td>
            </tr>
             <tr>
              <td style="border-color: transparent;"></td>
                        <td style="border-color: transparent;"></td>
                        <td style="border-color: transparent;"></td>
                        <td style="border-color: transparent;"></td>

              <td class="border-left-side" style="border-left: 2px solid #000;">G.Total</td>
              <td>${
                parseFloat(totalAmount) + parseFloat(totalAmount * 0.18)
              }</td>
            </tr>
          </tbody>
        </table>
         
            
                     <div class="terms-and-conditions">
                        <p style='text-decoration: underline; margin-top: 30px;' class="tc-details"><strong> TERMS & CONDITIONS FOR RENTAL:-<strong/> </p>
                        <ol>
                            <li style='font-weight: 400;'>TAXES APPLICABLE 18% AS PER GST.</li>
                            <li style='font-weight: 400;' >Rental period will be minimum 03 Month</li>
                            <li style='font-weight: 400;'>03 Month Rental payment Required as advance along with Rental Order</li>
                            <li style='font-weight: 400;'>Advance cannot be adjusted against the running rental invoices.</li>
                            <li style='font-weight: 400;'>Rental amount to be paid within 5 days from the date of submission of rental invoice.</li>
                            <li style='font-weight: 400;'>Transportation for both supply and return By PRIME INFRA</li>
                            <li style='font-weight: 400;'>While sending the material loading is our scope And unloading at site is Our scope.</li>
                            <li style='font-weight: 400;'>The date of rent commencement would be from the date of receivable of our materials.</li>
                            <li style='font-weight: 400;'>Quantities of the material supplied should be counted /verified in numbers at the timeof
                            Receipt and while return of the same.</li>
                            <li style='font-weight: 400;'>Damages or stolen material at the time of return will be charged for replacement.</li>
                            <li style='font-weight: 400;'>Labor for Installation is your scope and labor for Dismantling is also your Scope.</li>
                            <li style='font-weight: 400;'>While sending the material E-Way bill is our scope while returning the material e-Waybill is
                            our scope.</li>


                           
                        </ol>
                        <div>
                        
                    </div>
                    <div>
                    <p style='font-weight: 400; font-size: 14px;'>&emsp; &emsp; A Cheque of Material Value As Security For our Materials to Be Given in Fever Of Prime
                    Infra, And that will be returned at the time of return of our Material We assure you the best of
                    quality standards in each of our products supplied to you and the related services. <br/> We sincerely hope that the above rates are in line with your requirement and we hope to
                    receive the valuable Purchase Order from your esteemed organization at the earliest.
                    We are eager and are proud to be associated with your prestigious company. We wish you
                    success in your esteemed project. <br/> For any clarifications please feel free to contact us.</p>
                    </div>

                   
                       
                        <p class="frome-big"><strong>Thanks & Regards</strong></p>
                        <p class="frome-big"><strong>Prime Infra</strong></p>
                        <p class="frome-big"><strong>Ph:</strong> 9035981049</p>
                    </div>
        <div class="footer">
          <img src=${footer_img} alt="Footer Logo" />
        </div>
      </div>
    </body>
  </html>
`;

  return (
    <div>
      <button onClick={() => toPDF()}>Download PDF</button>
      <div
        ref={targetRef}
        dangerouslySetInnerHTML={{
          __html: templateHTML,
        }}
      />
    </div>
  );
};

export default SQInvoice;
