export default{
    // baseUrl:"http://192.168.88.84:5000/",
    
    // baseUrl:"http://192.168.88.84:5000/",
    // baseUrl:"http://192.168.92.90:5000/",
    // baseUrl: "https://us-central1-email-js-1a09b.cloudfunctions.net/backend/",

    // baseUrl: "https://birlaadvaya.xyz/",
    baseUrl: "https:",
    // baseUrl:"http://192.168.88.55:4000/",   
    // baseUrl:"http://192.168.31.24:4000/",   

    // baseUrl:"https://dev.localheros.in/"


    // baseUrl: "http://ec2-13-48-245-166.eu-north-1.compute.amazonaws.com:5000/",
    // baseUrl: "http://ec2-13-60-22-195.eu-north-1.compute.amazonaws.com:5000/",

}