import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Switch, Form, Input, Select, message } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc,orderBy,query } from "firebase/firestore";
import { db } from "../firebase/firebase"; // Import initialized Firestore instance
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Loader from "../components/Loader";

const { confirm } = Modal;

const ReportList = () => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState("");  // Filter for Material Type
  const [filterClient, setFilterClient] = useState("");  // Filter for Client

  const materialTypes = ["DC", "RDC", "NM", "UM", "SQ", "RQ"]; // Available material types

  // Fetch report_list from Firestore
  const fetchReportList = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, "report_list"), orderBy("productData.date", "desc"));
      const querySnapshot = await getDocs(q);
      const reportData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReportList(reportData);
      console.log(reportData);
      
    } catch (error) {
      console.error("Error fetching report_list:", error);
      message.error("Failed to fetch report list.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReportList();
  }, []);

  // Handle add or edit category
  const handleSaveEdit = async (editedData) => {
    const categoryData = {
      name: editedData.name,
      description: editedData.description,
      report_type: editedData.report_type,
      isActive: editedData.isActive,
      createdAt: editData.createdAt || new Date(),
    };

    try {
      if (editData.id) {
        // Update existing category
        await updateDoc(doc(db, "report_list", editData.id), categoryData);
        message.success("Category updated successfully.");
      } else {
        // Add new category
        await addDoc(collection(db, "report_list"), categoryData);
        message.success("Category added successfully.");
      }
      fetchReportList();
    } catch (error) {
      console.error("Error saving category:", error);
      message.error("Failed to save category.");
    } finally {
      setEditModalVisible(false);
    }
  };

  // Handle category deletion
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "report_list", id));
      message.success("Category deleted successfully.");
      fetchReportList();
    } catch (error) {
      console.error("Error deleting category:", error);
      message.error("Failed to delete category.");
    }
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure you want to delete this category?",
      icon: <DeleteFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record.id);
      },
    });
  };

  // Filter report list based on search input, type, and client
  const filteredReportList = reportList.filter((category) => {
    const isClientMatch =
      filterClient === "" ||
      (category.productData &&
        category.productData.name &&
        category.productData.name.toLowerCase().includes(filterClient.toLowerCase()));


    const isTypeMatch =
      filterType === "" ||
      (category && category?.report_type === filterType);

    return isClientMatch && isTypeMatch ;
  });

  const columns = [
    {
      title: "Client",
      key: "productData",
      width: "25%",
      render: (text, record) => {
        const clientData = record.productData;
        if (clientData) {
          return (
            <ul style={{ paddingLeft: "20px" }}>
              <li>Name: <strong>{clientData.name}</strong></li>
              <li>Date: {clientData.date}</li>
              <li>Mobile: {clientData.contact_number || "N/A"}</li>
              <li>Address: {clientData.address}</li>
            </ul>
          );
        }
        return "No Client data";
      },
    },
    {
      title: "Type",
      dataIndex: "report_type",
      key: "report_type",
      width: "15%",
    },
    {
      title: "Material",
      key: "material",
      width: "35%",
      render: (text, record) => {
        return record.Material && record.Material.length > 0 ? (
          <ul style={{ paddingLeft: "20px" }}>
            {record.Material.map((item, index) => (
              <li key={index}>
                <strong>{item.name}</strong> - {item.qty} {item.UOM} ({item.rate})
              </li>
            ))}
          </ul>
        ) : (
          "No material data"
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Button
            type="primary"
            size="small"
            onClick={() => handleEdit(record)}
            className="bg-white text-dark shadow-none"
          >
            <i className="fa-regular fa-pen-to-square " />
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => showDeleteConfirm(record)}
            className="bg-white text-dark shadow-none"
          >
            <i className="fa-solid fa-trash-can " />
          </Button>
        </>
      ),
    },
    {
      title: "Download",
      key: "download",
      width: "15%",
      render: (text, record) => {
        const clientData = record.productData;
        return clientData ? (
          <a href={record.downloadURL} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-download text-black" aria-hidden="true"></i> {clientData.name}
          </a>
        ) : (
          "No Client Data"
        );
      },
    },
  ];

  const EditModal = ({ visible, data, onClose, onSave }) => {
    const [form] = Form.useForm();

    const handleSave = () => {
      form
        .validateFields()
        .then((values) => {
          onSave(values);
          form.resetFields();
          onClose();
        })
        .catch((errorInfo) => {
          console.log("Validation Failed:", errorInfo);
        });
    };

    return (
      <Modal
        title="Edit Category"
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
      >
        <Form form={form} initialValues={data}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter category name" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="report_type" label="Report Type" rules={[{ required: true, message: "Please select report type" }]}>
            <Select>
              <Select.Option value="Type1">Type 1</Select.Option>
              <Select.Option value="Type2">Type 2</Select.Option>
              <Select.Option value="Type3">Type 3</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="isActive" label="Active" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const handleEdit = (record) => {
    setEditData(record);
    setEditModalVisible(true);
  };

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-10 bg-light">
            <div className="main m-lg-4 mx-2">
              <div className="overview mt-3 px-3 py-3 bg-white">
                <div className="row d-flex col-md-12">
                  <Input
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="mb-3 col-md-3"
                  />
                  <Select
                    placeholder="Select Type"
                    value={filterType}
                    onChange={setFilterType}
                    className="mb-3 col-md-3"
                  >
                    <Select.Option value="">All Types</Select.Option>
                    {materialTypes.map((type) => (
                      <Select.Option key={type} value={type}>
                        {type}
                      </Select.Option>
                    ))}
                  </Select>
                  <Input
                    placeholder="Search Client"
                    value={filterClient}
                    onChange={(e) => setFilterClient(e.target.value)}
                    className="mb-3 col-md-3"
                  />
                </div>

                <Table
                  dataSource={filteredReportList}
                  columns={columns}
                  loading={loading}
                  pagination={{ pageSize: window.innerWidth < 768 ? 8 : 10 }} // Smaller page size on mobile
                  size={window.innerWidth < 768 ? 'small' : 'middle'} // Compact table for mobile
                  scroll={{ x: 600 }} // Enables horizontal scroll on small screens if needed
                />
                <EditModal
                  visible={editModalVisible}
                  data={editData}
                  onClose={() => setEditModalVisible(false)}
                  onSave={handleSaveEdit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

export default ReportList;
