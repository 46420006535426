import React, { useRef } from "react";
import moment from "moment";
import { PDFExport } from "@react-pdf/renderer";
import { usePDF } from "react-to-pdf";
import header_img from "../constant/images/bg-bannerid.jpg";
import footer_img from "../constant/images/footer_id.jpg";

const InvoicePreview = ({ productData, gstadd, Site_Address }) => {
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });

  // Generate table rows dynamically
  const tableRows = productData.items
    .map((item, index) => {
      return `
        <tr>
          <td>${index + 1}</td>
          <td>${item.commodity}</td>
          <td>${item.uom}</td>
          <td>${item.quantity}</td>
        </tr>
      `;
    })
    .join("");

  // Calculate total quantity
  const totalQuantity = productData.items.reduce(
    (acc, item) => acc + item.quantity,
    0
  );

  const templateHTML = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Downloadable Invoice</title>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Bookman+Old+Style:wght@400;700&display=swap');

        body {
          font-family: 'Bookman Old Style', serif;
          background-color: #f5f5f5;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
        }

        .invoice {
          font-family: 'Bookman Old Style', serif;
          width: 250mm;
          min-height: 347mm;
          margin: 0 auto;
          background-color: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          padding: 35px 50px;
          position: relative;
          display: flex;
          flex-direction: column;
        }

        .invoice-header {
          font-family: 'Bookman Old Style', serif;
          text-align: center;
        }

        .invoice-header img {
          max-width: 100%;
          height: auto;
        }

        .invoice-header h1 {
          font-size: 28px;
          margin: 10px;
          font-family: 'Bookman Old Style', serif;
          font-weight: 700;
        }

        .frome-to {
          font-size: 18px;
          font-family: 'Bookman Old Style', serif;
          font-weight: 400;
        }

        .invoice-details {
          font-family: 'Bookman Old Style', serif;
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }

        .text-adjust p {
        font-family: 'Bookman Old Style', serif;
         line-height : 10px;
         
        }
      .text-adjust p strong {
        font-family: 'Bookman Old Style', serif;
         line-height : 20px;
         font-weight: bold;    
        font-size: 18px;
         
        }
         .mt-80 {
         margin-top : 140px
         }

        .invoice-items {
          font-family: 'Bookman Old Style', serif;
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;
        }

        .invoice-items th,
        .invoice-items td {
          font-family: 'Bookman Old Style', serif;
          border: 1px solid #000;
          padding: 5px;
          text-align: left;
          height: 20px;
        }

        .invoice-items th {
          font-family: 'Bookman Old Style', serif;
          font-weight: 500;
        }

        .invoice-items td {
          font-family: 'Bookman Old Style', serif;
          font-weight: 800;
        }

        .terms-and-conditions {
          font-family: 'Bookman Old Style', serif;
          font-size: 14px;
          
        }
        .terms-and-conditions strong {
          font-family: 'Bookman Old Style', serif;
          font-size: 18px;
          font-weight: 800;
        }
        .terms-and-conditions p {
          font-family: 'Bookman Old Style', serif;
          font-size: 18px;
          font-weight: 800;
          line-height : 18px;

        }

        .footer {
          font-family: 'Bookman Old Style', serif;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          padding: 10px 0;
        }

        .footer img {
          max-width: 100%;
          height: auto;
        }
        .line-space {
         line-height : 20px !important;
          

         }
      </style>
    </head>
    <body>
      <div class="invoice">
        <div class="invoice-header">
          <img src=${header_img} alt="Logo" />
          <h1>Delivery Challan</h1>
        </div>
        <div class="invoice-details">
          <div class="text-adjust mt-80">
            <p><strong>From:</strong> PRIME INFRA</p>
            <p>SY NO 158/1, KAMBIPURA VILLAGE, Kengeri Satellite Town,</p>
            <p>Bengaluru, Bengaluru Urban, Karnataka, 560060</p>
            <p><br /></p>
            <p><strong>To:</strong> ${productData.name}</p>
            <p>${productData.address}</p>
            ${gstadd}
            <p class="line-space"><strong> Delivery from Site</strong> : ${Site_Address} </p>
          </div>
          <div class="text-adjust">
            <p>GST: 29FXEPS6897F1Z1</p>
            <p>EWAYNO: ${productData.ewaynumber}</p>
            <p>DC NO: ${productData.dcno}</p>
            <p>Date: ${moment(productData.date).format("DD-MM-YYYY")}</p>
            <p>Vehicle NO: ${productData.veh_no}</p>
          </div>
        </div>
        <table class="invoice-items">
          <thead>
            <tr>
              <th>SL.NO</th>
              <th>COMMODITY</th>
              <th>UOM</th>
              <th>QUANTITY</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
            <tr>
              <td colspan="2"></td>
              <td>TOTAL</td>
              <td>${totalQuantity}</td>
            </tr>
          </tbody>
        </table>
        <div class="terms-and-conditions">
          <p><strong>Approx Material Value:</strong> Rs. ${
            productData.approxAmount
          }</p>
           <p><strong>Syed Maveez:</strong> 90359 81049</p>
            <p><strong>Terms & Conditions For Rental:</strong></p>

          <ol>
            <li>This Materials Are For Scaffolding and Shuttering Work</li>
            <li>This Material Is Not For Sale</li>
            <li>This Material Is Returnable</li>
            <li>This Material Is Only Owned By Prime Infra</li>
            <li>Any Material damages or Missing RECEIVABLES PARTY will have to bear the cost</li>
          </ol>
          <p><strong>Thanks & Regards</strong></p>
          <p><strong>Prime Infra</strong></p>
          <p><strong>Ph:</strong> 9035981049</p>
        </div>
        <div class="footer">
          <img src=${footer_img} alt="Footer Logo" />
        </div>
      </div>
    </body>
  </html>
`;

  return (
    <div>
       <button onClick={() => toPDF()}>Download PDF</button>
      <div
        ref={targetRef}
        dangerouslySetInnerHTML={{
          __html: templateHTML,
        }}
      />
     
    </div>
  );
};

export default InvoicePreview;
