import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  message,
  Modal,
  Select,
  Input,
  Form,
} from "antd";
import { db } from "../firebase/firebase"; // Import initialized Firestore instance
import { collection, getDocs } from "firebase/firestore";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Loader from "../components/Loader";
import { InboxOutlined } from "@ant-design/icons";
import { jsPDF } from "jspdf"; // Import jsPDF
import html2canvas from "html2canvas"; // Import html2canvas
import InvoicePreview from "../components/InvoicePreview";
import InvoiceM from "../components/InvoiceM";
import RDInvoice from "../components/RDinvoice";
import NewInvoice from "../components/NewInvoice";
import SQInvoice from "../components/SQInvoice";
import RQInvoice from "../components/RQinvoice";
import UmInvoice from "../components/UmInvoice";

const { Meta } = Card;
const { Option } = Select;

const Report_list = () => {
  const [reportList, setReportList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [rate, setRate] = useState(0);
  const [unit, setUnit] = useState("SQFT");
  const [total, setTotal] = useState(0);
  const [showprv, setShowPrv] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    ewaynumber: "",
    dcno: "",
    date: "",
    veh_no: "",
    name: "",
    address: "",
    approxAmount: "",
    items: [],
  });
  const [invoice, setInvoice] = useState({
    ewaynumber: "",
    dcno: "",
    date: "",
    veh_no: "",
    address: "",
    approxAmount: "",
    gst: "",
  });
  const [materialsAdd, setMaterialsAdd] = useState([
    {
      id: "",
      name: "",
      qty: 1,
      rate: 0,
      gst: 0,
      amount: 0,
    },
  ]);

  // Handler to add a new material row
  const handleAddMaterialRow = () => {
    const newMaterial = {
      id: "",
      name: "",
      qty: 1,
      rate: 0,
      gst: 0,
      amount: 0,
    };
    setMaterialsAdd([...materialsAdd, newMaterial]);
    message.success("New material row added!");
  };

  // Handler to update material row data
  const handleMaterialChange = (index, field, value) => {
    const updatedMaterials = [...materialsAdd];
    updatedMaterials[index][field] = value;

    if (field === "qty" || field === "rate") {
      updatedMaterials[index].amount =
        updatedMaterials[index].qty * updatedMaterials[index].rate;
    }

    setMaterialsAdd(updatedMaterials);
  };

  const handleMaterialDc = (index, field, value) => {
    const updatedMaterials = [...materialsAdd];
    updatedMaterials[index][field] = value;

    if (field === "qty" || field === "rate") {
      updatedMaterials[index].amount =
        updatedMaterials[index].qty * updatedMaterials[index].rate;
    }

    setMaterialsAdd(updatedMaterials);
  };

  const handlePreview = () => {
    const updatedInvoiceData = {
      ewaynumber: invoice.ewaynumber, // Replace with dynamic data
      dcno: invoice.dcno, // Replace with dynamic data
      date: new Date().toISOString().split("T")[0],
      veh_no: invoice.veh_no,
      name: selectedClient, // Use selected client data
      address:
        clientList.find((client) => client.name === selectedClient)?.address ||
        "",
      approxAmount: materialsAdd
        .reduce((acc, mat) => acc + mat.amount, 0)
        .toFixed(2),
      items: materialsAdd.map((material) => ({
        commodity: material.name,
        uom: material.unit,
        quantity: material.qty,
        rate: material.rate,
        amount: (material.quantity * material.rate).toFixed(2),
      })),
    };

    setInvoiceData(updatedInvoiceData);
    setShowPrv(true); // Open the preview modal
  };

  // Fetch reports, clients, and materials
  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch reports
      const reportsSnapshot = await getDocs(collection(db, "report_list"));
      const reportsData = reportsSnapshot.docs.map((doc) => ({
        _id: doc.id,
        id: doc.id,
        ...doc.data(),
      }));
      setReportList(reportsData);

      // Fetch clients
      const clientsSnapshot = await getDocs(collection(db, "client"));
      const clientsData = clientsSnapshot.docs.map((doc) => ({
        _id: doc.id,
        ...doc.data(),
      }));
      setClientList(clientsData);

      // Fetch materials
      const materialsSnapshot = await getDocs(collection(db, "Material"));
      const materialsData = materialsSnapshot.docs.map((doc) => ({
        _id: doc.id,
        ...doc.data(),
      }));
      console.log(materialsData, "materialsData");

      setMaterialList(materialsData);
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCardClick = () => {
    setModalVisible(true);
  };

  const handleReset = () => {
    setSelectedClient(null);
    setSelectedMaterial(null);
    setQuantity(0);
    setRate(0);
    setUnit("SQFT");
    setTotal(0);
  };

  const handleAddMaterial = () => {
    message.success("Material added!");
  };

  const handleSave = () => {
    message.success("Material details saved successfully!");
    setModalVisible(false);
  };

  const handleQuantityChange = (e) => {
    const qty = e.target.value;
    setQuantity(qty);
    setTotal(qty * rate);
  };

  const handleRateChange = (e) => {
    const rateValue = e.target.value;
    setRate(rateValue);
    setTotal(quantity * rateValue);
  };



  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-10 bg-light">
            <div className="main m-lg-4 mx-2">
              <div className="overview mt-3 px-3 py-3 bg-white">
                <Row gutter={[16, 16]}>
                  {/* Material Sections */}
                  {["Quotations Material"].map((section, index) => (
                    <Col span={12} key={index}>
                      <Card
                        title={section}
                        bordered={false}
                        bodyStyle={{ padding: "20px" }}
                      >
                        <Row gutter={[16, 16]}>
                          {[
                            "New Material",
                            "Used Material",
                            "Rental Quotation",
                            "Scaffolding Work Quotations",
                          ].map((item, idx) => (
                            <Col span={12} key={idx}>
                              <Card
                                hoverable
                                onClick={() => {
                                  handleCardClick();
                                  setSelectedMaterial(item);
                                }}
                                cover={
                                  <InboxOutlined
                                    style={{
                                      fontSize: "50px",
                                      marginTop: "20px",
                                    }}
                                  />
                                }
                              >
                                <Meta title={item} className="text-center" />
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Card>
                    </Col>
                  ))}
                  {["Delivery Challan"].map((section, index) => (
                    <Col span={12} key={index}>
                      <Card
                        title={section}
                        bordered={false}
                        bodyStyle={{ padding: "20px" }}
                      >
                        <Row gutter={[16, 16]}>
                          {["Delivery Challan", "Returnable Delivery"].map(
                            (item, idx) => (
                              <Col span={12} key={idx}>
                                <Card
                                  hoverable
                                  onClick={() => {
                                    handleCardClick();
                                    setSelectedMaterial(item);
                                  }}
                                  cover={
                                    <InboxOutlined
                                      style={{
                                        fontSize: "50px",
                                        marginTop: "20px",
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={item} className="text-center" />
                                </Card>
                              </Col>
                            )
                          )}
                        </Row>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {loading && <Loader />}

      {/* Material Details Modal */}
      <Modal
        title={selectedMaterial}
        visible={modalVisible}
        width={"1000px"}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="reset" onClick={handleReset}>
            Reset
          </Button>,
          <Button key="addMaterialRow" onClick={handleAddMaterialRow}>
            Add Material Row
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
          <Button key="generatePDFs" onClick={handlePreview} type="primary">
            Preview PDF
          </Button>,
        ]}
      >
        <div id="material-details">
          <Form layout="vertical">
            {/* Client, Material, and Date Fields */}
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item label="Client">
                  <Select
                    showSearch
                    value={selectedClient}
                    onChange={(value) => setSelectedClient(value)}
                    placeholder="Select Client"
                  >
                    {clientList.map((client) => (
                      <Option key={client._id} value={client.name}>
                        {client.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* Client Info */}
              <Col span={8}>
                {selectedClient && (
                  <div>
                    <p>
                      Name:{" "}
                      {
                        clientList.find(
                          (client) => client.name === selectedClient
                        )?.name
                      }
                    </p>
                    <p>
                      Address:{" "}
                      {
                        clientList.find(
                          (client) => client.name === selectedClient
                        )?.address
                      }
                    </p>
                    <p>
                      Mobile:{" "}
                      {
                        clientList.find(
                          (client) => client.name === selectedClient
                        )?.contact_number
                      }
                    </p>
                  </div>
                )}
              </Col>

              <Col span={8}>
                <Form.Item label="Date">
                  <Input type="date" />
                </Form.Item>
              </Col>
            </Row>
            {(selectedMaterial === "Delivery Challan" ||
              selectedMaterial === "Returnable Delivery") && (
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Form.Item label="E way Number">
                    <Input
                      value={invoice.ewaynumber}
                      onChange={(e) =>
                        setInvoice({
                          ...invoice, // Spread the existing fields to retain their values
                          ewaynumber: e.target.value, // Update only the ewaynumber field
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="DC No">
                    <Input
                      value={invoice.dcno}
                      onChange={(e) =>
                        setInvoice({
                          ...invoice, // Spread the existing fields to retain their values
                          dcno: e.target.value, // Update only the ewaynumber field
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Vehicle No">
                    <Input
                      value={invoice.veh_no}
                      onChange={(e) =>
                        setInvoice({
                          ...invoice, // Spread the existing fields to retain their values
                          veh_no: e.target.value, // Update only the ewaynumber field
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="GST No">
                    <Input
                      value={invoice.gst}
                      onChange={(e) =>
                        setInvoice({
                          ...invoice, // Spread the existing fields to retain their values
                          gst: e.target.value, // Update only the ewaynumber field
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Site Address">
                    <Input
                      value={invoice.address}
                      onChange={(e) =>
                        setInvoice({
                          ...invoice, // Spread the existing fields to retain their values
                          address: e.target.value, // Update only the ewaynumber field
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {/* Material Details: Name, Unit, Quantity */}
            {materialsAdd.map((material, index) => (
              <Row gutter={[16, 16]} key={index}>
                <Col span={8}>
                  <Form.Item label="Material">
                    <Select
                     showSearch
                      value={material.name}
                      onChange={(value) =>
                        handleMaterialChange(index, "name", value)
                      }
                      placeholder="Select Material"
                    >
                      {materialList.map((mat) => (
                        <Option key={mat._id} value={mat.name}>
                          {mat.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Unit">
                    <Select
                      value={material.unit}
                      onChange={(value) =>
                        handleMaterialChange(index, "unit", value)
                      }
                    >
                      {["SQFT", "CFT", "NOS", "METER", "KG"].map((unit) => (
                        <Option key={unit} value={unit}>
                          {unit}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Quantity">
                    <Input
                      value={material.qty}
                      onChange={(e) =>
                        handleMaterialChange(
                          index,
                          "qty",
                          Number(e.target.value)
                        )
                      }
                      type="number"
                      placeholder="Enter Quantity"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Rate">
                    <Input
                      value={material.rate}
                      onChange={(e) =>
                        handleMaterialChange(
                          index,
                          "rate",
                          Number(e.target.value)
                        )
                      }
                      type="number"
                      placeholder="Enter Rate"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Amount">
                    <Input value={material.amount} disabled />
                  </Form.Item>
                </Col>
              </Row>
            ))}

            {/* Total Amount Calculation */}
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item label="Total Amount">
                  <Input
                    value={materialsAdd.reduce(
                      (acc, mat) => acc + mat.amount,
                      0
                    )}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      <Modal
        visible={showprv}
        width={"1000px"}
        onCancel={() => setShowPrv(false)}
      >
        {selectedMaterial === "Delivery Challan" ? (
          <InvoicePreview
            productData={invoiceData}
            gstadd={`<p> <strong> GST</strong> : ${invoice.gst} </p>`}
            Site_Address={`${invoice.address} `}
          />
        ) : selectedMaterial === "Returnable Delivery" ? (
          <RDInvoice
            productData={invoiceData}
            gstadd={`<p> <strong> GST</strong> : ${invoice.gst} </p>`}
            Site_Address={`${invoice.address} `}
          />
        ) : selectedMaterial === "New Material" ? (
          <NewInvoice
            productData={invoiceData}
            gstadd={`<p> <strong> GST</strong> : ${invoice.gst} </p>`}
            Site_Address={`${invoice.address} `}
          />
        ) : selectedMaterial === "Scaffolding Work Quotations" ? (
          <SQInvoice
            productData={invoiceData}
            gstadd={`<p> <strong> GST</strong> : ${invoice.gst} </p>`}
            Site_Address={`${invoice.address} `}
          />
        ) : selectedMaterial === "Rental Quotation" ? (
          <RQInvoice
            productData={invoiceData}
            gstadd={`<p> <strong> GST</strong> : ${invoice.gst} </p>`}
            Site_Address={`${invoice.address} `}
          />
        ) : selectedMaterial === "Used Material" ? (
          <UmInvoice
            productData={invoiceData}
            gstadd={`<p> <strong> GST</strong> : ${invoice.gst} </p>`}
            Site_Address={`${invoice.address} `}
          />
        ) : (
          <InvoiceM
            productData={invoiceData}
            gstadd={`<p> <strong> GST</strong> : ${invoice.gst} </p>`}
            Site_Address={`${invoice.address} `}
          />
        )}
      </Modal>
    </>
  );
};

export default Report_list;
